$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
// endbower

@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&subset=latin,latin-ext);


//vars
$orange: #ffd400;
$grey: #515151;
$light-grey: #f0f0f0;
body {
  font-family: "Source Sans Pro", sans-serif;
}

.main-bg {
  background-image: url(../images/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.orange {color: $orange;}
.orange-bg {background: $orange;}
.small-icon {font-size: .7em;}

h1 {
  color: $orange;
  font-size: 3em;
}

h5 {
  line-height: 1.5em;
}

.first-section {
  min-height: 80vh;
  text-transform: uppercase;
  .slogan {
    background: rgba(#161616,.6);
    padding: 60px 10px;
    margin-top: 100px;
    margin-bottom: 100px;
    display: block;
    .logo {
      border-width: 1px 0;
      padding: 10px 0;
      border-style: solid;
      border-color: #fff;

      img {
        margin: 60px 10px;
      }
    }

    h2 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.5em;
      padding: 0px 20px;
      font-size: 1.9em;
      strong {
        font-weight: 700;
      }
    }

    .btn {
      background: none;
      border: 1px solid #fff;
      color: #fff;
      width: 40%;
      margin-top: 20px;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 1.2em;
      border-radius: 0px;
      padding: 10px 0px;

    }
  }

  .menu {
    margin-top: 10px;
    margin-right: 20px;
    .nav li a {
      font-size: 1.2em;

      &.active {
        font-weight: bold;
      }
      &:hover, &:focus {
        border-radius: 0;
        background: $orange;
        color: #fff;
      }
    }
  }

  a:link, a:visited {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #adadad;
  }

}

.second-section {
  h1, .advantages {
    margin-top: 100px;
  }

}

.third-section {
  background: $light-grey;
  margin-top: 100px;
  padding: 60px 15px 70px;

  .gallery {
    color: #fff;
    .single-image {
      margin-top: 50px;
    }
    .description {
      background: rgba(21,12,12, .2);
      position: absolute;
      width: calc(100% - 30px);
      height: 100%;
      transition: background .5s;

      h4 {
        text-transform: uppercase;
        padding: 180px 0px 0px 0px;
        font-size: 2em;
        transition-delay: 2s;
        transition: padding-top .3s;
      }
      h6 {
        font-size: 1.6em;
        font-weight: 300;
      }



      &:hover {
        background: rgba($orange, .5);
        transition: background .5s;

        h4 {
          transition-delay: .3s;
          transition-duration: .5s;
          transition-property: padding-top;
          padding-top: 140px;
        }
      }
    }
  }
}

.fifth-section {
  color: #fff;
  background: darken($grey, 20%);
  padding: 60px 0px 120px 0px;

  input, textarea, button.btn {
    border-radius: 0;
    background: none;
    border: 1px solid #fff;
    padding: 5px;
    margin: 20px 0;
  }

  button.btn {
    margin-top: 0;
    width: 100%;
    text-transform: uppercase;

    &:hover {
      color: #fff;
      background: $orange;
      border-color: $orange;
    }
  }

  h2, h3, h4, h5, h6 {
    font-weight: 300;
    line-height: 1.4em;
  }

  .section-content {
    margin-top: 70px;
  }

  iframe {
    width: 100%;
    border: 0px;
    height: 267px;
    margin-top: 19px;
  }
}


// elements and helpers


.advantage-icon {
  font-size: 40px !important;
  color: #fff;
  background: $orange;
  width: 2em;
  height: 2em;
  padding: .5em;
  border-radius: 50%;
}

.top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;

  a {
    background: #333;
    border-radius: 5px;
    color: #fff;
    padding: 14px 10px 12px;
  }
}


